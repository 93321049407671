<template>
  <div class="login-container">
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
      @submit.native.prevent
      class="login-form"
    >
    <!-- 版本切换 -->
      <h1 class="login-title">常州市智慧通行数字哨兵管控平台</h1>
      <el-form-item label="" prop="username">
        <el-input
          size="large"
          v-model="form.username"
          placeholder="请输入账号"
          prefix-icon="el-icon-user"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input
          size="large"
          v-model="form.password"
          show-password
          placeholder="请输入密码"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>

      <el-form-item label="" prop="inputCode">
        <el-input
          size="large"
          v-model="form.inputCode"
          prefix-icon="el-icon-picture-outline"
          placeholder="请输入验证码"
        >
          <template slot="append">
            <img
              v-if="requestCodeSuccess"
              style="width: 1rem"
              :src="randCodeImage"
              @click.prevent="changImage()"
            />
            <img
              v-else
              style="width: 1rem"
              src="../../assets/checkcode.png"
              @click.prevent="changImage()"
            />
          </template>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          class="submitStyle"
          @click="submitForm('form')"
          native-type="submit"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
     <script>
export default {
  data() {
    return {
      requestCodeSuccess: false,
      randCodeImage: "",
      currdatetime: "",
      form: {
        username: "",
        password: "",
        inputCode: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        inputCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.changImage();
    this.$store.commit("setSysOrgCodeValue", "");
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            username: this.form.username,
            password: this.form.password,
            captcha: this.form.inputCode,
            checkKey: this.currdatetime,
            remember_me: true,
          };
          this.$http.postLogin(`/sys/login`, { ...params }).then((data) => {
            console.log(data);
            if (data.code === 200) {
              if (data.result.token) {
                localStorage.setItem(
                  "pro__Access-Token",
                  JSON.stringify({value:data.result.token})
                );
                localStorage.setItem(
                  "prevention_web_user_roles",
                  data.result.roles
                );
                this.$router.push({ path: "/index" }).catch(() => {});
              }
            } else {
              this.$message.error(data.message);
            }
          });
        } else {
          console.log("验证失败");
          return false;
        }
      });
    },
    changImage() {
      this.currdatetime = new Date().getTime();
      this.form.inputCode = "";
      this.$http
        .get(`/sys/randomImage/${this.currdatetime}`, {})
        .then((data) => {
          if (data.success) {
            this.randCodeImage = data.result;
            this.requestCodeSuccess = true;
          } else {
            this.$message.error(data.message);
            this.requestCodeSuccess = false;
          }
        });
    },
  },
};
</script>
 
   <style acoped>
  
.login-form {
   /* 版本切换 */
  width: 35vw;
  /* width:30vw; */

  margin: 20vh auto; /* 上下间距160px，左右自动居中*/
  background-color: rgb(255, 255, 255, 0.2); /* 透明背景色 */
  padding: 8vh;
  border-radius: 20px; /* 圆角 */
  overflow: hidden;
}

/* 背景 */
.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../assets/loginBg.jpg");
}

/* 标题 */
.login-title {
  color: #f8f8f9;
  text-align: center;
  margin-bottom: 4vh;
}
.submitStyle {
  width: 100%;
}
.el-input-group__append {
  padding: 0;
}
</style> -->